var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { changeRow } from "@/services/datagrid";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
let DataGridCell = class DataGridCell extends Vue {
    constructor() {
        super(...arguments);
        this.cellValue = this.value;
    }
    get column() {
        return this.columns[this.name] ?? {};
    }
    get cellClasses() {
        const classes = {
            hidden: !this.column?.IsVisible ?? false,
            [this.column.Type]: true,
        };
        return classes;
    }
    get primaryKey() {
        return Object.values(this.columns).find((column) => column.IsPrimaryKey === true).Name;
    }
    get cellDataValue() {
        if (this.cellValue.Value) {
            return this.cellValue;
        }
        return "";
    }
    get cellComponent() {
        return () => import(`./datagridCellTypes/DatagridCell${this.column.Editor ?? this.column.Type ?? "Delete"}.vue`);
    }
    async rowChanged(changedData) {
        return changedData;
    }
    async rowUpdated(row) {
        return row;
    }
    valueChange(value) {
        this.cellValue = value;
    }
    cellValueChanged(newVal) {
        const changedData = {
            newVal: newVal.Value,
            prop: this.column.Name,
            primaryKey: this.row[this.primaryKey].Value,
            description: newVal.Text ?? newVal.Description,
        };
        this.commitRowChange(changedData);
    }
    async commitRowChange(changedData) {
        const row = this.row;
        const prop = changedData.prop;
        row[prop].Description = changedData.description;
        row[prop].Value = changedData.newVal;
        const result = await changeRow({
            subject: this.$store.getters.selectedWindow.output.Request.Subject,
            data: this.row,
        });
        const cellNames = Object.keys(result).filter((cellName) => cellName != this.primaryKey);
        const rowChanges = [];
        for (const cellName of cellNames) {
            const changedData = {
                newCellData: result[cellName],
                newVal: result[cellName].Value,
                prop: cellName,
                primaryKey: this.row[this.primaryKey].Value,
            };
            this.rowChanged(changedData);
            rowChanges.push(changedData);
        }
        this.rowUpdated(rowChanges);
    }
};
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "columns", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "row", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "value", void 0);
__decorate([
    Emit()
], DataGridCell.prototype, "rowChanged", null);
__decorate([
    Emit()
], DataGridCell.prototype, "rowUpdated", null);
DataGridCell = __decorate([
    Component
], DataGridCell);
export default DataGridCell;
